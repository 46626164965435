import React, { useEffect } from 'react';
import { 
    useColorModeValue,
    useToast,
 } from '@chakra-ui/react';

import { ConsultaSecciones } from './TextoPrincipal'

export function Welcome() {
    
    const bg = useColorModeValue('gray.700', 'gray.700')
    const color = useColorModeValue('gray.100', 'gray.100')

    const toast = useToast()
    const id = 'toast-helloWorld'

    // useEffect(() => {
        
        
    //     if (!toast.isActive(id)) {
    //         toast({ 
    //             id,
    //             description: "Although you can navigate through some corners, the site is under construction.",
    //             duration: 6000,
    //             isClosable: true,
    //             position: 'bottom',
    //             title: 'Website under construction!',
    //             status: 'info',
    //             variant: 'solid'
    //         })
    //     }

    // }, [])

    return (
        <>
            <ConsultaSecciones />
        </>
        )
}