import Flipper1 from '../../media/fotos/flipper1.jpg';
import Flipper2 from '../../media/fotos/flipper2.jpg';
import Electronics1 from '../../media/fotos/electronics1.webp';
import Midi1 from '../../media/fotos/midi1.webp';
import Stickers1 from '../../media/fotos/stickers1.webp';
import Sweets1 from '../../media/fotos/sweets1.webp';
import TelegramBot1 from '../../media/fotos/telegramBot1.jpg';
import BitsAT1 from '../../media/fotos/bitsAT1.webp';
import Video1 from '../../media/videos/video1.mp4';
import Cacharreo1 from '../../media/fotos/cacharreo1.jpg';
import Video2 from '../../media/videos/video2.mp4';
import Midi2 from '../../media/fotos/midi2.jpg';
import Punnta1 from '../../media/fotos/punnta1.jpg';
import Pi4Tomato from '../../media/fotos/pi4Tomato1.jpg';
import PiZero1 from '../../media/fotos/piZero1.jpg';
import Devices1 from '../../media/fotos/devices1.jpg';
import Rgbled from '../../media/fotos/rgbled.jpg';
import Chip32u4 from '../../media/fotos/32u41.jpg';
import Pedal1 from '../../media/fotos/pedal1.png';
import Pedal2 from '../../media/fotos/pedal2.jpg';
import Pedal3 from '../../media/fotos/pedal3.jpg';
import Pedal4 from '../../media/fotos/pedal4.jpg';
import Pedal5 from '../../media/fotos/pedal5.jpg';
import CincoBotones from '../../media/fotos/cincoBotones1.jpg';
import Working1 from '../../media/fotos/working1.jpg';

import FregaderoPortada1 from '../../media/fotos/Fregadero1.png';
import Fregadero1 from '../../media/fotos/fregadero1.jpg';
import Fregadero2 from '../../media/fotos/fregadero2.jpg';
import Fregadero3 from '../../media/fotos/fregadero3.jpg';
import Fregadero4 from '../../media/fotos/fregadero4.jpg';
import Fregadero5 from '../../media/fotos/fregadero5.jpg';
import Fregadero6 from '../../media/fotos/fregadero6.jpg';
import Fregadero7 from '../../media/fotos/fregadero7.jpg';
import Fregadero8 from '../../media/fotos/fregadero8.jpg';

import Buttons1 from '../../media/fotos/buttons1.jpg';
import Sim800l from '../../media/fotos/sim800l.jpg';
import Giveaway1 from '../../media/fotos/giveaway1.jpg';


const images = [
    {
      src: Flipper1,
      type: 'image',
      title: 'Flipper Zero',
      description: 'Jugando con Flipper Zero a partir de ahora ♥️ Voy a mostrar aquí el progreso 🔥 #FlipperZero #hacking #RFID #NFC #GPIO',
    },
    {
      src: Flipper2,
      type: 'image',
      title: 'Flipper GPIO',
      description: 'Jugando con Flipper Zero a partir de ahora ♥️ Voy a mostrar aquí el progreso 🔥 #FlipperZero #hacking #RFID #NFC #GPIO',
    },
    {
      src: Electronics1,
      type: 'image',
      title: 'Amo Diciembre',
      description: 'Diciembre es siempre una época de música y luces 💡 @arduino.cc @bareconductive @raspberrypi ❤️',
    },
    {
      src: Midi1,
      type: 'image',
      title: 'Protocolo MIDI',
      description: 'Acabo de terminar este esquema de la estructura con la que viajan los mensajes con el protocolo MIDI, son preciosos.',
    },
    {
      src: Stickers1,
      type: 'image',
      title: 'Pegatinas',
      description: 'Hay un dicho: ¡Donde hay código hay alegría! Y donde hay pegatinas también hay alegría  🥳',
    },
    {
      src: Sweets1,
      type: 'image',
      title: 'Golosinas',
      description: 'Rodeado de golosinas soy feliz!',
    },
    {
      src: TelegramBot1,
      type: 'image',
      title: 'Bot de Telegram',
      description: 'Ergo #blockchain + Raspberry Pi + Telegram bot = 🎉',
    },
    {
      src: BitsAT1,
      type: 'image',
      title: '8bits',
      description: 'Sigo trabajando en #8bitsAtErgo',
    },
    {
      src: Video1,
      type: 'video',
      title: 'Botones',
      description: 'Trabajando en los nuevos botones',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'Trabajando',
      description: 'Trabajando 👾',
    },
    {
        src: Video2,
        type: 'video',
        title: 'Desarrollo iOS',
        description: 'Activar el modo oscuro en el desarrollo de apps para iOS ⬛️',
        },
    {
        src: Midi2,
        type: 'image',
        title: 'Protocolo MIDI',
        description: 'Trabajando con protocolos MIDI, potenciómetros e impresión 3D ❤️🎛',
      },
    {
        src: Punnta1,
        type: 'image',
        title: 'Hello Punnta',
        description: 'Jugando con @bareconductive + @hellopunnta ❤️',
      },
    {
        src: Pi4Tomato,
        type: 'image',
        title: 'Raspberry Pi 4',
        description: '#RaspberryPi4 + tomates 🍅',
      },
    {
        src: PiZero1,
        type: 'image',
        title: 'Pi Zero',
        description: 'PiZero + PiCam + Telegram = ❤️',
      },
    // {
    //     src: PiZero1,
    //     type: 'image',
    //     title: 'PiZero',
    //     description: '❤️ Telegram + @raspberrypifoundation are love ❤️ Water pump activated and deactivated by a Telegram bot (written in Python and running on a Raspberry Pi 3) 💦',
    //   },
    {
        src: Devices1,
        type: 'image',
        title: 'Dispositivos iOS',
        description: 'Qué modelos de iPhone 📱 has tenido? Todos estos dispositivos han sido regalados por diferentes personas excepto el iPod 5 y el Mini iPad 👌',
      },
    {
        src: Rgbled,
        type: 'image',
        title: 'LED RGB',
        description: 'LED RGB visto con un microscopio de 60x 🔬 El valor del microscopio es de 1 euro',
      },
    {
        src: Chip32u4,
        type: 'image',
        title: '32u4 LOVER',
        description: 'Desde qué país nos sigues? 🌎🌍🌏 Trabajando con #32u4 ❤️',
      },
      {
          src: Pedal1,
        type: 'gallery',
        title: 'Hackeando un pedal',
        description: 'Hackeando un pedal con #Attiny85 en la música @ivannietomad 🤟🏽',
        relatedImages: [Pedal2, Pedal3, Pedal4, Pedal5],
    },
    {
        src: CincoBotones,
        type: 'image',
        title: 'Botones',
        description: 'Trabajando en un teclado MIDI que viajará a EE.UU 🤩',
      },
    {
        src: Working1,
        type: 'image',
        title: 'Trabajando',
        description: 'Trabajando con algunas de mis placas favoritas 😅',
      },
      {
        src: FregaderoPortada1,
      type: 'gallery',
      title: 'Fregadero y cocina',
      description: 'Todo hecho con materiales reciclados ❤️ Trabajando en el fregadero y la cocina para la furgoneta 🚐',
      relatedImages: [Fregadero1, Fregadero2, Fregadero3, Fregadero4, Fregadero5, Fregadero6, Fregadero7, Fregadero8],
  },
      {
        src: Buttons1,
        type: 'image',
        title: 'Botones',
        description: 'Los guerreros van para Bruselas 🤖',
  },
      {
        src: Sim800l,
        type: 'image',
        title: 'Módulos',
        description: '¿Cuál es su módulo favorito? Estoy enamorado de SIM800L 🤖',
  },
      {
        src: Giveaway1,
        type: 'image',
        title: 'Sorteo',
        description: 'Estoy pensando en hacer un nuevo #giveAway, ¿qué quieres?',
  },
  ];
  
  export default images;