// src/components/Galeria.js
import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import ImageCard from './ImageCard';
import media from './Data';

const ListadoConstrucciones = () => {
  return (
    <>
      <Box p={5}>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing="40px">
          {media.map((item, index) => (
            <ImageCard
              key={index}
              id={item.id}
              mediaSrc={item.mainImage}
              type={item.type}
              title={item.title}
              // description={item.description}
              // relatedImages={item.relatedImages || [item.src]}
            />
          ))}
        </SimpleGrid>
      </Box>
      <Box mt={5}></Box>
    </>
  );
};

export default ListadoConstrucciones;
