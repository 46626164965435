import React from 'react';
import {
    Text,
} from '@chakra-ui/react';

export function TextoInicioSensores(){

    return(
        <>
            <Text>
                
            </Text>
        </>
    )
}
