import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  extendTheme,
} from '@chakra-ui/react';

import { Header } from './components/Header'
import { Inicio } from './routes/inicio';
import { Footer } from './components/Footer'

const MiTema = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

function App() {
  return (
    <ChakraProvider theme={MiTema}>
      <Box textAlign="left" fontSize="xl">
        {/* <Grid minH="70vh" p={3}> */}
          <VStack>
            <Header />
            <Inicio />
            <Footer />
          </VStack>
        {/* </Grid> */}
      </Box>
    </ChakraProvider>
  );
}

export default App;
