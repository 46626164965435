import React, { useState } from 'react';
import { useRoute, useLocation } from 'wouter';
import { 
  Heading, VStack, Text, Box, 
  SimpleGrid, Modal, ModalOverlay, 
  ModalContent, ModalCloseButton, ModalBody, 
  Button, IconButton
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export function DetallesConstrucciones({ construcciones }) {
  
  const [location, setLocation] = useLocation();
  const irConstruyendo = () => {
      setLocation("/construyendo");
  };
  
  const [match, params] = useRoute('/construyendo/:id');
  const projectId = params?.id;

  const project = construcciones.find(proj => proj.id === projectId);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  if (!project) {
    return (
      <VStack minW='90%' maxW='90%' mt={10}>
        <Heading>No se encontró el proyecto</Heading>
      </VStack>
    );
  }

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImageIndex(null);
  };

  const prevImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex - 1 + project.images.length) % project.images.length
    );
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => 
      (prevIndex + 1) % project.images.length
    );
  };

  return (
    <>
      <VStack
        minW='90%'
        maxW='90%'
        mt={10}
        spacing={6}
        align="start"
        p={6}
      >
        <Heading as="h1" size="2xl" mb={4} color="white">
          {project.title}
        </Heading>
        <Text fontSize="lg" lineHeight="tall" color="gray.200" dangerouslySetInnerHTML={{ __html: project.description }} />
        <Box
          mb={6}
          borderRadius="lg"
          overflow="hidden"
          boxShadow="md"
          
        >
          <img
            src={project.mainImage}
            alt={project.title}
            style={{ width: '100%', height: 'auto', cursor: 'pointer', aspectRatio: '1/1', objectFit: 'cover', borderRadius: 'lg' }}
          />
        </Box>

        <Heading>Galería fotográfica</Heading>

        <SimpleGrid columns={{ sm: 1, md: 3, lg: 8 }} spacing="20px">
          {project.images && project.images.map((image, index) => (
            <Box
              key={index}
              mb={6}
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              onClick={() => openModal(index)}
            >
              <img
                src={image}
                alt={`${project.title} - ${index + 1}`}
                style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              />
            </Box>
          ))}
        </SimpleGrid>
        
        <Button borderRadius={'full'} onClick={() => irConstruyendo()}>
          Volver
        </Button>
        
        <Box m={2} mb={4}></Box>
      </VStack>

      <Modal isOpen={isOpen} onClose={closeModal} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Box
            px={4}
            py={2}
            borderBottomWidth="1px"
            borderBottomColor="gray.200"
            bg="gray.800"
            color="white"
            fontWeight="bold"
            fontSize="lg"
            textAlign="center"
          >
            {project.title} - {selectedImageIndex + 1}
          </Box>
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            p={0}
          >
            {project.images && project.images.length > 0 && (
              <>
                <IconButton
                  aria-label="Anterior imagen"
                  icon={<ChevronLeftIcon />}
                  onClick={prevImage}
                  position="absolute"
                  left="10px"
                  zIndex="1"
                  colorScheme="green"
                />
                <img
                  src={project.images[selectedImageIndex]}
                  alt={`${project.title} - ${selectedImageIndex + 1}`}
                  style={{ width: '100%', height: 'auto', maxHeight: '90vh', objectFit: 'contain' }}
                />
                <IconButton
                  aria-label="Siguiente imagen"
                  icon={<ChevronRightIcon />}
                  onClick={nextImage}
                  position="absolute"
                  right="10px"
                  zIndex="1"
                  colorScheme="green"
                />
              </>
            )}
          </ModalBody>
          
        </ModalContent>
      </Modal>
    </>
  );
}
