import React from 'react';
import {
    Text,
} from '@chakra-ui/react';

export function TextoInicioCv(){

    return(
        <>
            <Text ml={2}>
                Ampliando mis conocimientos en seguridad informática y tecnología blockchain.
            </Text>
        </>
    )
}
