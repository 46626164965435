import React from 'react';
import {
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import { BloqueInfo} from './BloqueInfo';

export function AreaConocimientos(){

    const bg = useColorModeValue('gray.200', 'gray.700')
    const color = useColorModeValue('gray.800', 'gray.200')    
    
    return(
        <>

            <Box p={5}></Box>
            
            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    cabecera={'Intereses principales'}
                    titulo='Frontend'
                    descripcion='Algunas de las tecnologías con las que he trabajado y trabajo actualmente.'
                    badge3='Angular'
                    badge4='Chakra-UI'
                    badge1='React'
                    badge2='Svelte'
                    badge5='TailWind'
                    colorBadge={'orange'}
                />
            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    titulo='Backend'
                    descripcion='Algunas de las tecnologías con las que he trabajado y trabajo actualmente.'
                    badge1='Firebase'
                    badge2='Flask'
                    badge3='MongoDB'
                    badge4='Python'
                    badge5='SQL'
                    colorBadge={'orange'}
                />
            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    titulo='Electrónica'
                    descripcion='Desarrollo de proyectos e ideas de electrónica experimental. Desde plasmar la idea en papel hasta la puesta en marcha del prototipo. He sido patrocinado por marcas como: Bare Conductive, micro:bit, Particle y Nordic Semiconductor.'
                    badge1='Arduino'
                    badge2='Raspberry Pi'
                    badge3='Soldadura SMD'
                    badge4='3D Printing'
                    colorBadge={'orange'}
                />

            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    titulo='Blockchain'
                    descripcion="Desde 2019 aprendiendo y creando aplicaciones de forma voluntaria con tecnología blockchain en Ergo Platform."
                    badge1='Ergo Platform'
                    badge2='SIGMA'
                    badge3='Blockchain'
                    colorBadge={'orange'}
                />
            
            <Box p={10}></Box>
            
        </>
    )
}
