import React from 'react';
import { 
    Code,
    Heading,
    Stack,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { TextoPresentaConstrucciones } from '../components/construyendo/TextoPresentaConstrucciones';
import { TextoInicioConstruyendo } from '../components/construyendo/TextoInicioConstruyendo';

export function Construyendo(){

    const toast = useToast()
    const id = 'test-toast'

    if (!toast.isActive(id)) {
        toast({ 
        id,
        description: "Estoy actualizando esta sección para que puedas ver algunos de los proyectos que tengo construidos, la mayoría son de Electrónica Experimental, aunque no todos.",
        duration: 10000,
        isClosable: true,
        position: 'down',
        title: 'Sección en construcción',
        status: 'info',
        variant: 'solid'
        })
    }

    return(
        <>
            <VStack minW='90%' maxW='90%' mt={10}>
                <Heading>Construyendo</Heading>
                
                <Stack direction='row'>
                    <Code 
                        mb={'20px'}
                        colorScheme='cyan' 
                        children='Construyendo cosas por diversión' />
                </Stack>
                
                <TextoPresentaConstrucciones />
                <TextoInicioConstruyendo />
                                
            </VStack>
            
        </>
    )
}
