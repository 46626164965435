import React from 'react';
import { 
    Code,
    Heading,
    Stack,
    VStack,
} from '@chakra-ui/react';

import { UltimasFotografias } from '../components/instagram/UltimasFotografias';
import { TextoInicioInstagram } from '../components/instagram/TextoInicioInstagram';

export function Instagram(){

    return(
        <>
            <VStack minW='90%' maxW='90%' mt={10}>
                <Heading>Instagram</Heading>
                
                <Stack direction='row'>
                    <Code 
                        mb={'20px'}
                        colorScheme='cyan' 
                        children='Algunos recuerdos de mi cuenta de instagram' />
                </Stack>
                
                <UltimasFotografias />
                <TextoInicioInstagram />
                                
            </VStack>
            
        </>
    )
}
