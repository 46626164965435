import React from 'react';
import {
    Center,
  } from '@chakra-ui/react';

import { MenuSwitch } from './MenuSwitch';

export function Inicio(){

    return(
        <>  
        <Center>
            <MenuSwitch />
        </Center>
        </>
    )
}
