import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { Link } from 'wouter';

const ImageCard = ({ id, mediaSrc, type, title }) => {
  return (
    <Box>
      <Link href={`/construyendo/${id}`}>
        <Box maxW="xs" borderWidth="1px" borderRadius="lg" overflow="hidden" cursor="pointer">
          {type === 'proyectos' && (
            <Box position="relative" width="100%" paddingTop="100%"> {/* Contenedor cuadrado */}
              <Image
                src={mediaSrc}
                alt={title}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                objectFit="cover" // Hace que la imagen cubra todo el contenedor
              />
            </Box>
          )}
          <Box p="6">
            <Box display="flex" alignItems="baseline">
              <Box
                color="green.500"
                fontWeight="bold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
              >
                <Text>{title}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default ImageCard;
