import React from 'react';
import {
    Box,
    Text,
} from '@chakra-ui/react';

export function TextoInicioConstruyendo(){

    return(
        <>
            <Text>
                
            </Text>
        </>
    )
}
