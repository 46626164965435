import React from 'react';
import { 
    Box,
 } from '@chakra-ui/react'
import { ConsultaNftExplorer } from './ConsultaNftExplorer';

export function MuestroNft(){

    return(
        <>
            <ConsultaNftExplorer />
            <Box p={3}></Box>
        </>
    )
}