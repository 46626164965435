// src/components/Galeria.js
import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import ImageCard from './ImageCard';
import media from './Data';

const Galeria = () => {
  return (
    <>
    <Box p={5}>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing="20px">
        {media.map((item, index) => (
          <ImageCard
            key={index}
            mediaSrc={item.src}
            type={item.type}
            title={item.title}
            description={item.description}
            relatedImages={item.relatedImages || [item.src]} // Default to single image if no related images
          />
        ))}
      </SimpleGrid>
    </Box>

  <Box p={10}></Box>  
  </>
  );
};

export default Galeria;
