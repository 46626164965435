import React from 'react';

import { Welcome } from '../components/Welcome'
export function Home(){
    return(
        <>
            <Welcome />
        </>
    )
}
