import * as React from 'react'
import { 
    ButtonGroup,  
    HStack, 
    IconButton, 
    Link,
    Stack, 
    Text,
    useColorModeValue } from '@chakra-ui/react';
import { 
    FaGithub, 
    FaInstagram, 
    FaLinkedin } from 'react-icons/fa'


import { LuPartyPopper } from "react-icons/lu";


export function Footer(){

    const bg = useColorModeValue('gray.700', 'gray.700')
    const color = useColorModeValue('gray.100', 'gray.100')

    return(
        <>
            <div
                style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                right: 0,
                }}
            >
                
                    <Stack spacing={{ base: '4', md: '5' }} p={3} bg={bg}>
                        <HStack>
                            <Text fontSize='xs' color={color}>
                                <HStack>
                                    <Text>
                                    Disfruta algunos tutoriales sobre python y bash en{' '}
                                        <Link href="https://oyola.dev" isExternal color="orange">
                                            oyola.dev
                                        </Link>
                                    </Text>
                                    <LuPartyPopper/>
                                </HStack>
                            </Text>
                        </HStack>
                    </Stack>
                
            </div>
        </>
    )
}
