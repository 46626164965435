import React from 'react';
import { 
    Box,
} from '@chakra-ui/react';
import ListadoConstrucciones from './ListadoConstrucciones';
// import { BloqueInfo } from './BloqueInfo';

export function TextoPresentaConstrucciones(){

    return(
        <>
                <Box pt={10}> </Box>
                Algunos proyectos de Electrónica Experimental que he construido
                <ListadoConstrucciones />
                

        </>
    )
}
