import React from 'react';
import { 
    Box,
} from '@chakra-ui/react';

import Galeria from './Galeria';

export function TextoPresentaSensores(){

    return(
        <>
                
        <Box pt={10}>
        </Box>
            Me gusta incluir sensores y módulos de todo tipo en mis proyectos de Electrónica Experimental...
        <Galeria />

        </>
    )
}
