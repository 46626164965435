import React from 'react';
import { 
    Badge,
    Heading,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
} from '@chakra-ui/react';

export function BloqueInfo({colorBg, colorColor, cabecera, titulo, fecha, descripcion, colorBadge, badge1, badge2, badge3, badge4, badge5, badge6, badge7 }){

    return(
        <>
            <Heading>{cabecera}</Heading>

            <Stat bg={colorBg} p={5} width={'full'} color={colorColor} >
                <StatNumber>{titulo}</StatNumber>
                <StatHelpText>{fecha}</StatHelpText>
                <StatLabel mb={1}>{descripcion}</StatLabel>
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge1}</Badge>
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge2}</Badge>
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge3}</Badge>  
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge4}</Badge>  
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge5}</Badge> 
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge6}</Badge> 
                <Badge mr={2} colorScheme={colorBadge} fontSize='0.8em'>{badge7}</Badge>   
            </Stat>
        </>
    )
}
