import React, { useState } from 'react';
import { Box, Image, Badge, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, SimpleGrid, useDisclosure, Button } from '@chakra-ui/react';

const ImageCard = ({ mediaSrc, type, title, description, relatedImages }) => {
  const maxDescriptionLength = 80; // Define la longitud máxima deseada

  const truncateDescription = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    onOpen();
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % relatedImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + relatedImages.length) % relatedImages.length);
  };

  return (
    <Box>
      <Box maxW="xs" borderWidth="1px" borderRadius="lg" overflow="hidden" cursor="pointer">
        {type === 'gallery' && (
           <Image src={mediaSrc} alt={title} boxSize="full" objectFit="cover" onClick={() => openModal(0)} />
        )}

        {type === 'image' && (
          <Image src={mediaSrc} alt={title} boxSize="full" objectFit="cover" onClick={() => openModal(0)} />
        )}

        {type === 'video' && (
          <Box
            as="video"
            src={mediaSrc}
            width="full"
            height="auto"
            onClick={() => openModal(0)}
            controls
            style={{ objectFit: 'cover' }}
          />
        )}

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="teal">
              {type}
            </Badge>
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {title}
            </Box>
          </Box>

          <Text mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
            {title}
          </Text>

          <Text mt="2">
          {truncateDescription(description, maxDescriptionLength)}
          </Text>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center">

              {type === 'image' && (
                <Image src={relatedImages[currentImageIndex]} alt={title} />
              )}

              {type === 'gallery' && (
                 <Image src={relatedImages[currentImageIndex]} alt={title} />
              )}
              
              {type === 'video' && (
                <Box textAlign="center">
                  <Box
                    as="video"
                    src={mediaSrc}
                    width="100%"
                    height="auto"
                    controls
                    style={{ objectFit: 'contain' }}
                  />
                </Box>
              )}
      
              

            </Box>
            
            <Text mt="2">{description}</Text>
          </ModalBody>
          <ModalFooter>
            {relatedImages.length > 1 && (
              <Button colorScheme="gray" mr={3} onClick={prevImage}>
                ←
              </Button>
            )}
            {relatedImages.length > 1 && (
              <Button colorScheme="gray" onClick={nextImage}>
                →
              </Button>
            )}
            <Button colorScheme="gray" ml={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ImageCard;
