import React from 'react';
import { 
    Code,
    Heading,
    Stack,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { TextoPresentaSensores } from '../components/sensores/TextoPresentaSensores';
import { TextoInicioSensores } from '../components/sensores/TextoInicioSensores';

export function Sensores(){

    return(
        <>
            <VStack minW='90%' maxW='90%' mt={10}>
                <Heading>Sensores</Heading>
                
                <Stack direction='row'>
                    <Code 
                        mb={'20px'}
                        colorScheme='cyan' 
                        children='Algunos de los sensores con los que trabajo' />
                </Stack>
                
                <TextoPresentaSensores />
                <TextoInicioSensores />
                                
            </VStack>
            
        </>
    )
}
