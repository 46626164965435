import React from 'react';
import { 
    Box,
} from '@chakra-ui/react';
import Galeria from './Galeria';

export function UltimasFotografias(){

    return(
        <>
                <Box pt={10}>
                </Box>
                Electrónica experimental y código, entre otras cosas...
                    <Galeria />
                

        </>
    )
}
