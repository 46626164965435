import React from 'react';
import { Switch, Route } from 'wouter'

import { Cv } from '../pages/Cv'
import { Home } from '../pages/Home'
import { NoExiste } from '../pages/NoExiste';
import { Instagram } from '../pages/Instagram';
import { Construyendo } from '../pages/Construyendo';
import { DetallesConstrucciones} from '../components/construyendo/DetallesConstrucciones';
import DatosDeConstrucciones from '../components/construyendo/Data';

import { Proyectos } from '../pages/Proyectos';

import { Sensores } from '../pages/Sensores';


// import { Skills } from '../pages/Skills';
// import { Proyectos } from '../pages/Proyectos';

export function MenuSwitch({visible}){
    return(
    <>
        <Switch>
            <Route exact  path="/" component={Home} /> 
            
            <Route exact  path="/cv" component={Cv} />
            
            <Route exact  path="/construyendo" component={Construyendo} />
            
            <Route exact  path="/construyendo/:id">
                <DetallesConstrucciones construcciones={DatosDeConstrucciones} />
            </Route>  

            <Route exact  path="/sensores" component={Sensores} />

            <Route exact  path="/instagram" component={Instagram} />

            {/* <Route exact  path="/proyectos" component={Proyectos} /> */}
            
            <Route exact component={NoExiste} />
            
            


            {/* <Route exact  path="/competencias" component={Skills} params={visible=false}/> */}
        </Switch>
    </>
    )
}