import React from 'react';
import { 
    Code,
    Heading, 
    Stack,
    VStack,
} from '@chakra-ui/react';
import { ExperienciaLaboral } from '../components/cv/ExperienciaLaboral';
import { Estudios } from '../components/cv/Estudios';
import { AreaConocimientos } from '../components/cv/AreaConocimientos';
import { TextoInicioCv } from '../components/cv/TextoInicioCv';

export function Cv() {

    return(
        <>
            <VStack 
                // backgroundColor={'gray.700'} 
                mt={10} 
                rounded={'2xl'}
                width='80%' // Fija el ancho a 600px
                maxW='80%' // Asegura que no se expanda más allá de 600px
                minW='80%' // Asegura que no se contraiga menos de 600px
                >
                <Heading>cv</Heading>
                <Stack direction='row'>
                    <Code 
                        mb={'20px'}
                        colorScheme='cyan' 
                        children='desarrollador full stack' />
                </Stack>
                <TextoInicioCv />
                <Estudios />
                <AreaConocimientos />
                <ExperienciaLaboral />
            </VStack>
            
        </>
    )
}
