import BraillePrincipal from '../../media/fotos/braille-principal.jpg';
import Braille1 from '../../media/fotos/braille-1.jpg';
import Braille2 from '../../media/fotos/braille-2.jpg';
import Braille3 from '../../media/fotos/braille-3.jpg';
import Braille4 from '../../media/fotos/braille-4.jpg';
import Braille5 from '../../media/fotos/braille-5.jpg';
import Braille6 from '../../media/fotos/braille-6.jpg';
import Braille7 from '../../media/fotos/braille-7.jpg';
import Braille9 from '../../media/fotos/braille-9.jpg';
import Braille10 from '../../media/fotos/braille-10.jpg';

import Alarmaprincipal from '../../media/fotos/alarma-principal.jpg';
import Alarma1 from '../../media/fotos/alarma-1.jpg';
import Alarma2 from '../../media/fotos/alarma-2.jpg';

import PotenciometroPrincipal from '../../media/fotos/potenciometros-principal.jpg'
import Potenciometro1 from '../../media/fotos/potenciometros-1.jpg'
import Potenciometro2 from '../../media/fotos/potenciometros-2.jpg'
import Potenciometro3 from '../../media/fotos/potenciometros-3.jpg'
import Potenciometro4 from '../../media/fotos/potenciometros-4.jpg'
import Potenciometro5 from '../../media/fotos/potenciometros-5.jpg'
import Potenciometro6 from '../../media/fotos/potenciometros-6.jpg'
import Potenciometro7 from '../../media/fotos/potenciometros-7.jpg'
import Potenciometro8 from '../../media/fotos/potenciometros-8.jpg'
import Potenciometro9 from '../../media/fotos/potenciometros-9.jpg'
import Potenciometro10 from '../../media/fotos/potenciometros-10.jpg'
import Potenciometro11 from '../../media/fotos/potenciometros-11.jpg'

import FlipperCC1101Princial from '../../media/fotos/flipper_cc1101_principal.jpg'
import FlipperCC11011 from '../../media/fotos/flipper_cc1101_1.jpg'
import FlipperCC11012 from '../../media/fotos/flipper_cc1101_2.jpg'
import FlipperCC11013 from '../../media/fotos/flipper_cc1101_3.jpg'
import FlipperCC11014 from '../../media/fotos/flipper_cc1101_4.jpg'
import FlipperCC11015 from '../../media/fotos/flipper_cc1101_5.jpg'
import FlipperCC11016 from '../../media/fotos/flipper_cc1101_6.jpg'
import FlipperCC11017 from '../../media/fotos/flipper_cc1101_7.jpg'

import BotonesPrincipal from '../../media/fotos/botones_principal.jpg'
import Botones1 from '../../media/fotos/botones_1.jpg'
import Botones2 from '../../media/fotos/botones_2.jpg'
import Botones3 from '../../media/fotos/botones_3.jpg'
import Botones4 from '../../media/fotos/botones_4.jpg'
import Botones5 from '../../media/fotos/botones_5.jpg'
import Botones6 from '../../media/fotos/botones_6.jpg'
import Botones7 from '../../media/fotos/botones_7.jpg'
import Botones8 from '../../media/fotos/botones_8.jpg'
import Botones9 from '../../media/fotos/botones_9.jpg'
import Botones10 from '../../media/fotos/botones_10.jpg'
import Botones11 from '../../media/fotos/botones_11.jpg'

import PedalPrincipal from '../../media/fotos/pedal-principal.jpg'
import Pedal1 from '../../media/fotos/pedal-1.jpg'
import Pedal2 from '../../media/fotos/pedal-2.jpg'
import Pedal3 from '../../media/fotos/pedal-3.jpg'

const construcciones = [
    
  {
    id: 'flipper-cc1101',
    mainImage: FlipperCC1101Princial, // Imagen principal
    images: [FlipperCC11011, FlipperCC11012, FlipperCC11013, FlipperCC11014, FlipperCC11015, FlipperCC11016, FlipperCC11017], // Array de imágenes relacionadas
    type: 'proyectos',
    title: 'Flipper Zero CC1101',
    description: `Creando un módulo casero para Flipper Zero del transceptor de radiofrecuencia CC1101.
    <br><br>

    <strong>Materiales</strong>
    <ul>
      <li>Módulo CC1101.</li>
      <li>Placa protoboard y algunos cables.</li>
      <li>Pines de ángulo.</li>
      <li>Soldador y algo de estaño.</li>
    </ul>

     <br>
    <strong>Detalles del proyecto</strong>
    <br>
    Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
    de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
    
    <br><br>
    
    <ul>
      <li>Explicación detallada del proyecto.</li>
      <li>Esquema de conexión.</li>
      <li>Código completo.</li>
    </ul>

    `,
  },{
    id: 'potenciometros-midi',
    mainImage: Potenciometro5, // Imagen principal
    images: [Potenciometro1, Potenciometro2, Potenciometro6, Potenciometro3, Potenciometro4, Potenciometro5, PotenciometroPrincipal, Potenciometro7, Potenciometro8, Potenciometro9, Potenciometro10, Potenciometro11], // Array de imágenes relacionadas
    type: 'proyectos',
    title: 'Potenciómetros MIDI',
    description: `Diferentes dispositivos USB con potenciómetros para enviar mensajes MIDI principalmente. Se pueden construir con Arduino Micro Pro o con Attiny85, siendo este segundo más económico.
    <br>
    La caja la he diseñado con OpenScad desde cero y está impresa con una Ender 3 y filamento PLA.
    <br><br>

    <strong>Materiales</strong>
    <ul>
      <li>Arduino Micro Pro.</li>
      <li>Attiny85.</li>
      <li>Potenciómetros.</li>
      <li>Placa protoboard y algunos cables.</li>
      <li>Soldador y algo de estaño.</li>
      <li>Caja diseñada con OpenScad e impresa con una impresora 3D Ender.</li>
    </ul>

     <br>
    <strong>Funcionamiento</strong>
    <br>
    Dependiendo del número de potenciómetros podemos asignar diferentes funciones, desde un simple control de volumen o pitch hasta el envío de notas por mensajes MIDI mientras giramos los controles.
    <br>
    Para darle diferentes usos podemos delirar todo lo que seamos capaces, dan mucho juego. Podemos usar alguna librería MIDI o Keyboard y que en vez de enviar mensajes MIDI realice funciones de teclado o incluso de ratón.

    <br><br>
    <strong>Detalles del proyecto</strong>
    <br>
    Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
    de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
    
    <br><br>
    
    <ul>
      <li>Explicación detallada del proyecto.</li>
      <li>Esquema de conexión.</li>
      <li>Código completo.</li>
    </ul>

    `,
  }, {
    id: 'hackeo-pedal',
    mainImage: PedalPrincipal, // Imagen principal
    images: [Pedal1, Pedal2, Pedal3], // Array de imágenes relacionadas
    type: 'proyectos',
    title: 'Hackeo pedal costura',
    description: `Hackeando un pedal de costura con Attiny85 para agregar funcionalidad de teclado. Con este pedal se puede pulsar la tecla o combinación
    de teclas que se configuren previamente.
    <br><br>

    <strong>Materiales</strong>
    <ul>
      <li>Pedal de costura.</li>
      <li>Attiny85.</li>
      <li>Algunos cables.</li>
      <li>Cable USB.</li>
      <li>Soldador y algo de estaño.</li>
    </ul>

     <br>
    <strong>Detalles del proyecto</strong>
    <br>
    Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
    de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
    
    <br><br>
    
    <ul>
      <li>Explicación detallada del proyecto.</li>
      <li>Esquema de conexión.</li>
      <li>Código completo.</li>
    </ul>

    `,
  },{
    id: 'botones-usb',
    mainImage: BotonesPrincipal, // Imagen principal
    images: [Botones1, Botones2, Botones3, Botones4, Botones5, Botones6, Botones7, Botones8, Botones9, Botones10, Botones11], // Array de imágenes relacionadas
    type: 'proyectos',
    title: 'Botones USB',
    description: `Diferentes botones USB para enviar mensajes MIDI o simular que pulsamos teclas de nuestro teclado.
    <br>
    La caja la he diseñado con OpenScad desde cero y está impresa con una Ender 3 y filamento PLA.
    <br><br>
    <strong>El programa El Hormiguero utilizó uno de mis botones para el juego Tenis Mental que fue pulsado por Will Smith :)</strong>
    <br><br>

    <strong>Materiales</strong>
    <ul>
      <li>Arduino Micro Pro.</li>
      <li>Pulsador de color.</li>
      <li>Placa protoboard y algunos cables.</li>
      <li>Soldador y algo de estaño.</li>
      <li>Caja diseñada con OpenScad e impresa con una impresora 3D Ender.</li>
    </ul>

     <br>
    <strong>Funcionamiento</strong>
    <br>
    Dependiendo de nuestras necesidades podemos asignar diferentes funciones a cada botón, desde una simple pulsación de tecla hasta el envío de notas por mensajes MIDI a nuestro programa de edición musical.
    <br>
    Para darle diferentes usos podemos delirar todo lo que seamos capaces, dan mucho juego ya que no tienen latencia prácticamente. Son ideales para activar y desactivar procesos en entornos industriales.

    <br><br>
    <strong>Detalles del proyecto</strong>
    <br>
    Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
    de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
    
    <br><br>
    
    <ul>
      <li>Explicación detallada del proyecto.</li>
      <li>Esquema de conexión.</li>
      <li>Código completo.</li>
    </ul>

    `,
  },
    {
      id: 'teclado-braille',
      mainImage: BraillePrincipal, // Imagen principal
      images: [Braille7, Braille10, Braille1, Braille2, Braille4, Braille3, Braille9, Braille5, Braille6 ], // Array de imágenes relacionadas
      type: 'proyectos',
      title: 'Pulsador Braille USB',
      description: `En este proyecto creo un <strong>dispositivo Braille de bolsillo</strong> que realiza las funciones de teclado.
      <br> No soy ningún especialista en este sistema de numeración, la intención con este proyecto es acercar un <strong>dispositivo Braille USB</strong> a todas
      las personas que quieran crearlo.
      
      <br><br>

      <strong>Materiales</strong>
      <ul>
        <li>Arduino Micro Pro.</li>
        <li>6 pulsadores.</li>
        <li>6 resistencias.</li>
        <li>Placa protoboard y algunos cables.</li>
        <li>Soldador y algo de estaño.</li>
        <li>Caja diseñada con OpenScad e impresa con una impresora 3D Ender.</li>
      </ul>
      
      <br>
      <strong>Funcionamiento</strong>
      <br>
      Cada una de las 6 posiciones del sistema Braille puede estar con valor 0 (sin pulsar) o con valor 1 (pulsado).
      Conociendo los botones que están pulsados podemos conocer la letra que se está intentando escribir.

      <br><br>
      <strong>Lógica aplicada</strong>
      <br>
      La lógica que he aplicado para que funcione es muy sencilla, mediante <i>estructuras if </i>detecto si cada uno de los pulsadores está siendo pulsado o no,
      en base al resultado sabemos qué letra se está queriendo escribir.

      <br><br>
      <strong>Detalles del proyecto</strong>
      <br>
      Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
      de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
      
      <br><br>
      
      <ul>
        <li>Explicación detallada del proyecto.</li>
        <li>Esquema de conexión.</li>
        <li>Código completo.</li>
        <li>Archivo del diseño de OpenScad para imprimir la caja.</li>
      </ul>

      `,
    },
    {
      id: 'alarma-con-llamada',
      mainImage: Alarmaprincipal, // Imagen principal
      images: [Alarma1, Alarma2], // Array de imágenes relacionadas
      type: 'proyectos',
      title: 'Alarma que te llama al teléfono',
      description: `Alarma casera con sensor de ultrasonido y módulo SIM800L que realiza una llamada de forma automática o te envía un SMS al número de teléfono que tengas configurado.
      <br><br>

      <strong>Materiales</strong>
      <ul>
        <li>Arduino Uno.</li>
        <li>Placa de protoboard.</li>
        <li>Sensor ultrasónico HC-SR04.</li>
        <li>Módulo SIM800L.</li>
        <li>Tarjeta SIM con saldo.</li>
      </ul>

       <br>
      <strong>Funcionamiento</strong>
      <br>
      El Arduino está monitorizando constantemente los valores del sensor de ultrasonido. Este sensor funciona como sensor de distancia, 
      utilizando el tiempo que tarda en recibir el rebote de las ondas que envía para calcular la distancia. Podríamos decir que usa un sistema 
      similar al de los murciélagos.
      <br>
      En mi proyecto, el sensor está midiendo constantemente la distancia, distancia que siempre es la misma porque lo tengo colocado en una pared y tiene otra justo en frente, en el momento
      que algo pasa por delante e interrumpe el ir y venir de las ondas con normalidad, el tiempo de recepción del rebote cambia y a su vez la distancia. De esta forma se activa la alarma.
      <br><br>
      Esta alarma no tiene sonido (podría agregarse), la idea es que nos avise haciendo uso del módulo SIM800L que incorpora, que puede configurarse 
      para que realice una llamada o envíe un SMS al número que tengamos definido.
      

      <br><br>
      <strong>Detalles del proyecto</strong>
      <br>
      Si quieres conocer más información sobre este proyecto, el esquema de conexión o que te pase el código empleado solo tienes que pedírmelo, estaré encantado
      de hacerte llegar todo. No le asigné ninguna licencia al proyecto pero puedes usarlo para lo que quieras.
      
      <br><br>
      
      <ul>
        <li>Explicación detallada del proyecto.</li>
        <li>Esquema de conexión.</li>
        <li>Código completo.</li>
      </ul>

      `,
    },
    // {
    //   id: 'App python para cámaras por RSTP',
    //   mainImage: TelegramBot1, // Imagen principal
    //   images: [TelegramBot1, AnotherImage], // Array de imágenes relacionadas
    //   type: 'proyectos',
    //   title: 'Aplicación Python para cámaras de escritorio y web con Flask',
    //   description: 'Mostrar la parte creada con Flask',
    // },
    // {
    //   id: 'potenciometros',
    //   mainImage: TelegramBot1, // Imagen principal
    //   images: [TelegramBot1, AnotherImage], // Array de imágenes relacionadas
    //   type: 'proyectos',
    //   title: 'Potenciómetros',
    //   description: 'Proyecto de 2 potenciómetros de línea',
    // },
    // {
    //   id: 'telefono-movil',
    //   mainImage: TelegramBot1, // Imagen principal
    //   images: [TelegramBot1, AnotherImage], // Array de imágenes relacionadas
    //   type: 'proyectos',
    //   title: 'Teléfono móvil',
    //   description: 'Teléfono móvil con Arduino para usar con blockchain',
    // },
    // {
    //   id: 'bot-telegram',
    //   mainImage: TelegramBot1, // Imagen principal
    //   images: [TelegramBot1], // Array de imágenes relacionadas
    //   type: 'proyectos',
    //   title: 'Bot Telegram',
    //   description: 'Bot Telegram para las cámaras de casa',
    // },
    // {
    //   id: 'crear-tokens-flipper',
    //   mainImage: TelegramBot1, // Imagen principal
    //   images: [TelegramBot1], // Array de imágenes relacionadas
    //   type: 'proyectos',
    //   title: 'Flipper create tokens',
    //   description: 'Crear tokens con Flipper por ondas de radio',
    // },
  ];
  
  export default construcciones;