import React, {useEffect, useState} from 'react';
import { 
    Box,
    Code,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    HStack,
    Icon,
    Image,
    Stack,
    Text,
    useDisclosure } from '@chakra-ui/react'

import { toUtf8String, resolveIpfs } from '../utils/funcionesNft';
import imagenNft from '../utils/nft.png'

export function ConsultaNftExplorer(){

    const [nft, setNft] = useState('')
    
    useEffect(() => {        
        fetch('https://api.ergoplatform.com/api/v0/assets/9bb730f4973793800a80f2e588a4ae427d8f6d44376fa235ad1e67e195716c4b/issuingBox')
            .then(response => response.json())
            .then(infoToken => {
                const id = infoToken[0].assets[0].tokenId
                const tx = infoToken[0].txId
                const alturaBloque = infoToken[0].creationHeight
                const amount = infoToken[0].assets[0].amount
                const decimales = infoToken[0].assets[0].decimals
                const tipo = infoToken[0].assets[0].type
                const nombreSinUtf8 = infoToken[0].additionalRegisters.R4
                const nombre = toUtf8String(infoToken[0].additionalRegisters.R4).substring(2)
                const descripcion = toUtf8String(infoToken[0].additionalRegisters.R5).substring(2)
                const sha = infoToken[0].additionalRegisters.R8.substring(4)
                const urlImageAux = toUtf8String(infoToken[0].additionalRegisters.R9)
                const urlRender = resolveIpfs(urlImageAux.substring(2))
                const objetoInfoNft = {
                    id: id,
                    tx: tx,
                    alturaBloque: alturaBloque,
                    amount: amount,
                    nombre: nombre, 
                    nombreSinUtf8: nombreSinUtf8,
                    descripcion: descripcion,
                    decimales: decimales,
                    tipo: tipo,
                    sha: sha,
                    url: urlRender
                }
                setNft(objetoInfoNft)
                
            })
    }, [])

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return(
        <>
            
            <Image 
                borderRadius='lg'
                width={{ md: 40 }}
                src={imagenNft}
                alt={nft.nombre}
                // ref={btnRef} onClick={onOpen}
                    />
            

        {/* Consulta Drawer */}
            <Drawer isOpen={isOpen} placement='bottom' onClose={onClose} finalFocusRef={btnRef} >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    Slightly more technical information
                </DrawerHeader>
                
                <DrawerBody>
                <Image
                    alt={nft.nombre}
                    borderRadius='lg'
                    mb='15px'
                    src={imagenNft}
                    width={{ md: 450 }}
                />
                
                <Text mb={'10px'} fontSize={'20px'} color={'cyan.300'}>I retrieve the information from this NFT by consuming an ErgoPlatform API.</Text>
                <Text mb={'10px'} fontSize={'14px'} color={'gray.400'}>By means of the hook of useEffect() I make a fetch to the Ergo Platform API and with the answer I create an object. You can see part of the code written just below the NFT information.</Text>
                <Text mb={'10px'} fontSize={'20px'} color={'cyan.600'}>Slightly more technical information</Text>
                <Box fontSize={'small'}>
                    <Stack direction='row' mb={1}>
                        <Code children='CODED NAME' />
                        <Text>{nft.nombreSinUtf8}</Text>
                    </Stack>
                    <Stack direction='row' mb={1}>
                        <Code children='UNCODED NAME' />
                        <Text>{nft.nombre}</Text>
                    </Stack>
                    <Stack direction='row' mb={1}>
                        <Code children='DESCRIPTION' />
                        <Text>{nft.descripcion}</Text>
                    </Stack>
                    <Stack direction='row' mb={1}>
                        <Code children='ID' />
                        <Text>{nft.id}</Text>
                    </Stack>
                    <Stack direction='row' mb={1}>
                        <Code children='TX' />
                        <Text>{nft.tx}</Text>
                    </Stack>
                    <HStack mb={1}>
                        <Stack direction='row' >
                            <Code children='HEIGHT' />
                            <Text>{nft.alturaBloque}</Text>
                        </Stack>
                        <Stack direction='row' >
                            <Code children='Quantity' />
                            <Text>{nft.amount}</Text>
                        </Stack>
                    </HStack>
                    <HStack mb={1}>
                        <Stack direction='row' >
                            <Code children='DECIMALS' />
                            <Text>{nft.decimales}</Text>
                        </Stack>
                        <Stack direction='row' >
                            <Code children='TYPE' />
                            <Text>{nft.tipo}</Text>
                        </Stack>
                    </HStack>
                    <Stack direction='row' mb={1}>
                        <Code children='SHA' />
                        <Text>{nft.sha}</Text>
                    </Stack>
                </Box>
                <Code textAlign={'start'} mt={'10px'} colorScheme='blue' width={'full'} >
                        <pre> {
                        `
    // This is how I perform the query to display this information.

    const [nft, setNft] = useState('')

    useEffect(() => {

        const id = 9bb730f4973793800a80f2e588a4ae427d8f6d44376fa235ad1e67e195716c4b
        
        fetch('https://api.ergoplatform.com/api/v0/assets/' + id + '/issuingBox')
            .then(response => response.json())
            .then(infoToken => {
                I create my object
            }
            setNft(objetoInfoNft)
        })

    }, [])
                        `}
                        </pre>
                </Code>
                <Text mt={'10px'} fontSize={'14px'} color={'gray.400'}>
                    The fetch url could have been constructed using inverted quotes.
                </Text>
                </DrawerBody>

                <DrawerFooter>
                    <Icon viewBox='0 0 200 200' color='cyan.200' mr='2px'>
                        <path
                            fill='currentColor'
                            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                            />
                    </Icon>
                    <Icon viewBox='0 0 200 200' color='cyan.300' mr='2px'>
                        <path
                            fill='currentColor'
                            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                            />
                    </Icon>
                    <Icon viewBox='0 0 200 200' color='cyan.400' mr='2px'>
                        <path
                            fill='currentColor'
                            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                            />
                    </Icon>
                    I have to admit that I love this NFT that I got as a gift.
                </DrawerFooter>

            </DrawerContent>
            </Drawer>
        </>
    )
}