
import Cacharreo1 from '../../media/fotos/cacharreo1.jpg';

import Video2 from '../../media/videos/video2.mp4';

import Pedal1 from '../../media/fotos/pedal1.png';
import Pedal2 from '../../media/fotos/pedal2.jpg';
import Pedal3 from '../../media/fotos/pedal3.jpg';
import Pedal4 from '../../media/fotos/pedal4.jpg';
import Pedal5 from '../../media/fotos/pedal5.jpg';



const images = [
    {
      src: Cacharreo1,
      type: 'image',
      title: 'MQ-7',
      description: 'Trabajando 👾',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'HC-SR04',
      description: 'Trabajando 👾',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'FC-28',
      description: 'Trabajando 👾',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'DHT11',
      description: 'Trabajando 👾',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'SIM800L',
      description: 'Trabajando 👾',
    },
    {
      src: Cacharreo1,
      type: 'image',
      title: 'PIR',
      description: 'Trabajando 👾',
    },

    {
      src: Pedal1,
      type: 'gallery',
      title: 'Hackeando un pedal',
      description: 'Hackeando un pedal con #Attiny85 en la música @ivannietomad 🤟🏽',
      relatedImages: [Pedal2, Pedal3, Pedal4, Pedal5],
    }, 

    {
      src: Video2,
      type: 'video',
      title: 'Desarrollo iOS',
      description: 'Activar el modo oscuro en el desarrollo de apps para iOS ⬛️',
    },
  ];
  
  export default images;